import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import { useMediaQuery } from "usehooks-ts";

import "./src/styles/reset.css";
import "./src/styles/typography.css";
import "./src/styles/spacing.css";
import "./src/styles/colors.css";
import "./src/styles/main.css";

export function wrapPageElement({ element }) {
	const userPrefersReducedMotion = useMediaQuery("(prefers-reduced-motion)");
	const windowIsNarrow = !useMediaQuery("(min-width: 60rem)");
	return (
		<ParallaxProvider isDisabled={windowIsNarrow || userPrefersReducedMotion}>
			{element}
		</ParallaxProvider>
	);
}
